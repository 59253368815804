import { Header } from "./Header";
import { Footer } from "./footer/Footer";
import { Flex } from "@chakra-ui/react";
import { Step } from "./Step";
import { RoomProvider } from "../liveblocks.config";
import { useParams } from "react-router-dom";
import { VideoSidebar } from "./video_common/VideoSidebar";
import { TourVideoSidebar } from "./video_common/productTour/TourVideoSidebar";
import { LeftSidebar } from "./LeftSidebar";
import { PropsWithChildren, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { chatwootVisible } from "../appState";
import { trpc } from "../hooks/useTRPC";
import {
    useGroupId,
    useHasVisitedAnySession,
    useImmersion,
    useIsChimeEnabled,
} from "./hooks/useImmersion";
import {
    useEtherpadCookie,
    useIsPreImmersion,
    useIsProductTour,
    useSessionContent,
} from "./hooks/useSessionContent";
import { Loading } from "../Loading";
import { Error } from "../Error";
import { RoomSetter } from "./RoomSetter";
import { NetworkQualityWarning } from "./video/NetworkQualityWarning";
import { NetworkQualityWarningChime } from "./video_chime/NetworkQualityWarningChime";
import { usePostHog } from "posthog-js/react";

export const IMMERSION_SIZES = {
    HEADER_HEIGHT: "6vh",
    FOOTER_HEIGHT: "10vh",
    STEP_MARGIN_TOP: "28px",
    STEP_MARGIN_BOTTOM: "28px",
    STEP_MARGIN_LEFT: "16px",
    STEP_MARGIN_RIGHT: "16px",
    CONTENT_MARGIN_LEFT: "20px",
    CONTENT_MARGIN_RIGHT: "20px",
};

export const Immersion = () => {
    const posthog = usePostHog();
    const { id, stepNumber, groupId: paramsGroupId } = useParams();
    const storedGroupId = useGroupId();
    const groupId = paramsGroupId || storedGroupId;
    const immersion = useImmersion();
    const isPreImmersion = useIsPreImmersion();
    const isProductTour = useIsProductTour();

    // @ts-ignore: Unused variable
    const isChimeEnabled = useIsChimeEnabled();

    const etherpadCookie = useEtherpadCookie();
    const maxVisited = Number(localStorage.getItem(`Visited-${id}`));
    localStorage.setItem(
        `Visited-${id}`,
        `${Math.max(Number(stepNumber), maxVisited)}`,
    );
    const sessionContent = useSessionContent();
    const setChatwootVisible = useSetRecoilState(chatwootVisible);
    const { mutateAsync: markVisited } =
        trpc.immersion.markSessionParticipantVisited.useMutation();
    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        STEP_MARGIN_LEFT,
        STEP_MARGIN_RIGHT,
        CONTENT_MARGIN_LEFT,
        CONTENT_MARGIN_RIGHT,
    } = IMMERSION_SIZES;

    useEffect(() => {
        setChatwootVisible(false);
    }, []);

    useEffect(() => {
        if (id && sessionContent && !isNaN(Number(stepNumber))) {
            markVisited({
                sessionId: id,
                stepNumber: Number(stepNumber),
                stepRecordId: sessionContent[Number(stepNumber)]?.recordId || null,
            });
        }
    }, [sessionContent, stepNumber, id]);

    useEffect(() => {
        const loadImages = async (sessionContent: any[]) => {
            const images = new Set<string>();
            // Preload image data for the next step only.
            const step = sessionContent[Number(stepNumber)];
            if (!step) {
                return;
            }
            if (step.resources?.resource1?.[0]?.type === "Image file") {
                images.add(step.resources?.resource1?.[0]?.url);
            }
            if (step.resources?.resource2?.[0]?.type === "Image file") {
                images.add(step.resources?.resource2?.[0]?.url);
            }
            if (step.resources?.resource3?.[0]?.type === "Image file") {
                images.add(step.resources?.resource3?.[0]?.url);
            }

            const promises = await Array.from(images).map((url: string) => {
                return new Promise((resolve, reject) => {
                    const image = new Image();

                    image.src = url;
                    image.onload = resolve;
                    image.onerror = reject;
                });
            });

            await Promise.all(promises);
        };
        if (sessionContent) {
            loadImages(sessionContent);
        }
    }, [sessionContent, stepNumber]);

    useEffect(() => {
        // Cookie which expires in 1 day.
        document.cookie = `sessionID=${etherpadCookie}; max-age=86400; path=/; Domain=.sparkwise.co`;
    }, [etherpadCookie]);
    if (!useHasVisitedAnySession()) {
        posthog.capture("Users first session.");
    }

    if (immersion?.isLoading) {
        return <Loading></Loading>;
    }
    if (!immersion || immersion?.isError) {
        return <Error text={immersion?.error.message} />;
    }
    return (
        <RoomWrapper groupId={groupId || undefined}>
            <Flex
                h={"100vh"}
                w="100vw"
                flexDirection={"column"}
                justifyContent="space-between"
                backgroundColor="#FCFCFD"
            >
                <Header />
                <Flex
                    h="100%"
                    justifyContent={"space-between"}
                    m={`0px ${CONTENT_MARGIN_RIGHT} 0px ${CONTENT_MARGIN_LEFT}`}
                >
                    {!isPreImmersion && <LeftSidebar />}
                    <Flex
                        flex="1"
                        margin={`${STEP_MARGIN_TOP} ${STEP_MARGIN_RIGHT} ${STEP_MARGIN_BOTTOM} ${STEP_MARGIN_LEFT}`}
                        position="relative"
                    >
                        <Step />
                    </Flex>
                    {isProductTour ? <TourVideoSidebar /> : <VideoSidebar />}
                </Flex>
                <Footer />
                {!isProductTour &&
                    (isChimeEnabled ? (
                        <NetworkQualityWarningChime />
                    ) : (
                        <NetworkQualityWarning />
                    ))}
            </Flex>
        </RoomWrapper>
    );
};

const RoomWrapper: React.FC<
    PropsWithChildren<{
        groupId?: string;
    }>
> = ({ children, groupId }) => {
    if (groupId) {
        return (
            <RoomProvider id={groupId} initialPresence={{ stepNumber: 0 }}>
                <RoomSetter>{children}</RoomSetter>
            </RoomProvider>
        );
    }
    return children;
};
