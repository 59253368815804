import { useEffect } from "react";
import { useStepContent } from "../hooks/useSessionContent";
import { ImageResource } from "./resources/ImageResource";
import {
    Box,
    Button,
    Center,
    Flex,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

export const ViewSampleAnswer = () => {
    const { resources } = useStepContent();
    const resource1 = resources?.resource1?.[0];
    const imageUrl = resource1?.url ?? "";
    const imageAlt = resource1?.name ?? "";
    const { isOpen, onOpen, onClose } = useDisclosure();
    let fadeEdges = isOpen ? true : false;

    useEffect(() => {
        onOpen();
    }, []);

    return (
        <>
            <ImageResource
                border
                fade={fadeEdges}
                url={imageUrl}
                altText={imageAlt}
                fullscreen
            />

            <Flex
                h="100%"
                w="100%"
                position="absolute"
                visibility={isOpen ? "visible" : "hidden"}
            >
                <Box
                    w="100%"
                    bg="whiteAlpha.500"
                    backdropFilter="blur(10px)"
                    position="relative"
                />

                <Center position="absolute" w="100%" h="100%">
                    <Flex direction="column" alignItems="center">
                        <Text
                            w="380px"
                            fontSize="20px"
                            fontWeight="semibold"
                            lineHeight="1.3"
                            textAlign="center"
                            color="gray.900"
                            fontFamily="Proxima Nova"
                        >
                            Reveal after you have finished the previous step.
                        </Text>
                        <Button
                            bg="gray.900"
                            color="white"
                            fontSize="14px"
                            fontFamily="Inter"
                            fontWeight="500"
                            mt="28px"
                            p="16px 12px"
                            borderRadius="6px"
                            onClick={onClose}
                            _hover={{ bg: "gray.700" }}
                        >
                            Reveal
                        </Button>
                    </Flex>
                </Center>
            </Flex>
        </>
    );
};
