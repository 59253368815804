import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Avatar,
    Box,
    Flex,
    Image,
    Progress,
    SlideFade,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { trpc } from "../../hooks/useTRPC";
import { useGroupId, useSelfData } from "../hooks/useImmersion";
import { useAccount } from "../../hooks/useAccount";
import { Header } from "../Header";
import { MatchingFooter } from "./MatchingFooter";
import { mindsetQuotes, statusTextVariants } from "./constants";
import {
    broadcasting,
    scaleFadeCircle,
    scaleFadeIn,
    scaleFadeOutline,
    scaleFadeSparkle,
} from "./animations";
import matchedSoundFile from "../../assets/UI-sound_matched.wav";
import readySoundFile from "../../assets/UI-sound_ready.wav";
import MatchedSparkleAvatar from "../../assets/matched_sparkle_avatar.svg";

type MatchingStatus = {
    status: "grouped" | "waiting" | "rescheduled" | "groupedOnLoad";
    data?: {
        groupId: string;
        participants: {
            id: string;
            firstName: string | null;
            lastName: string | null;
            avatarUrl: string | null;
        }[];
    };
};

type ProgressBarVariant =
    | "stepInProgress"
    | "stepDone"
    | "groupingDone"
    | "rescheduled";

export const MatchingScreen = () => {
    const account = useAccount();
    const groupId = useGroupId();
    const [currentStatus, setCurrentStatus] = useState<MatchingStatus>({
        status: "waiting",
    });
    const [progressBarsComplete, setProgressBarsComplete] = useState<boolean[]>(
        [false, false, false],
    );
    const numBarsComplete = progressBarsComplete.filter((bar) => bar).length;
    const progressDone = numBarsComplete === 3;
    const loadingBarWidth =
        numBarsComplete === 0 || numBarsComplete === 3
            ? "100%"
            : numBarsComplete === 1
              ? "66%"
              : "33%";
    const [statusText, setStatusText] = useState<string>(
        statusTextVariants.waiting.start,
    );
    const subHeaderText =
        currentStatus.status === "waiting"
            ? "Matching typically takes less than a minute"
            : "";
    const [showFooter, setShowFooter] = useState<boolean>(false);
    const [showGroupmates, setShowGroupmates] = useState<boolean>(false);
    const intervalRefCurrentStatus = useRef<NodeJS.Timeout | undefined>(
        undefined,
    );
    const timeoutRefLongWait = useRef<NodeJS.Timeout | undefined>(undefined);
    const intervalRefMindsetQuote = useRef<NodeJS.Timeout | undefined>(
        undefined,
    );
    const isMountedRef = useRef<boolean>(true);
    const participantId = useSelfData().sessionParticipantUuid;
    const { id: timeslotId } = useParams();
    const otherGroupParticipants = useMemo(() => {
        if (currentStatus.data) {
            return currentStatus.data.participants.filter(
                (p) => p.id !== participantId,
            );
        }
        return [];
    }, [currentStatus, participantId]);
    const [selectedQuoteIndex, setSelectedQuoteIndex] = useState<number>(
        Math.floor(Math.random() * mindsetQuotes.length),
    );
    const [transitionIn, setTransitionIn] = useState(true);
    const matchedSound = useMemo(() => new Audio(matchedSoundFile), []);
    const readySound = useMemo(() => new Audio(readySoundFile), []);

    const group = trpc.immersion.getGroup.useQuery(
        { groupId: groupId || "" },
        { enabled: !!groupId },
    );
    const { mutateAsync: addToMatchingQueue } =
        trpc.matching.addToMatchingQueue.useMutation({
            // the interval to check status must be set after successfully adding to queue
            // so that we won't get a false "reschedule" because addToMatchingQueue has not finished
            onSuccess: () => {
                // Only set interval if component is still mounted
                // (it's possible that the component is unmounted before addToMatchingQueue finishes, but
                // the app is still mounted (e.g. user navigates to another page) and we don't want to
                // continue running an interval in that case)
                if (isMountedRef.current) {
                    // make sure there is not already an interval before setting a new one
                    if (intervalRefCurrentStatus.current) {
                        clearInterval(intervalRefCurrentStatus.current);
                    }
                    const interval = setInterval(async () => {
                        const status = await getMatchingStatus({
                            participantId,
                        });
                        setCurrentStatus(status);
                    }, 15000);
                    intervalRefCurrentStatus.current = interval;
                }
            },
        });
    const { mutateAsync: removeFromMatchingQueue } =
        trpc.matching.removeFromMatchingQueue.useMutation();
    const { mutateAsync: getMatchingStatus } =
        trpc.matching.getMatchingStatus.useMutation();
    const { mutateAsync: markVisited } =
        trpc.immersion.markSessionParticipantVisited.useMutation();

    const restartMatching = () => {
        setCurrentStatus({ status: "waiting" });
        setProgressBarsComplete([false, false, false]);
        setStatusText(statusTextVariants.waiting.start);
        setShowFooter(false);
        addToMatchingQueue({ participantId });
        intervalRefMindsetQuote.current = setInterval(() => {
            setTransitionIn(false);
            setTimeout(() => {
                setTransitionIn(true);
                setSelectedQuoteIndex(
                    Math.floor(Math.random() * mindsetQuotes.length),
                );
            }, 500);
        }, 8000);
    };

    // Mark participant as visited once session id has loaded
    useEffect(() => {
        if (timeslotId) {
            markVisited({
                sessionId: timeslotId,
                stepNumber: null,
                stepRecordId: null,
            });
        }
    }, [timeslotId]);

    // Setting up mount/unmount actions
    useEffect(() => {
        isMountedRef.current = true;
        if (!participantId) {
            return;
        }
        if (groupId) {
            setCurrentStatus({ status: "groupedOnLoad" });
            return;
        }
        addToMatchingQueue({ participantId });
        timeoutRefLongWait.current = setTimeout(() => {
            setStatusText(statusTextVariants.waiting.long);
        }, 30000);
        intervalRefMindsetQuote.current = setInterval(() => {
            setTransitionIn(false);
            setTimeout(() => {
                setTransitionIn(true);
                setSelectedQuoteIndex(
                    Math.floor(Math.random() * mindsetQuotes.length),
                );
            }, 500);
        }, 8000);
        const cleanup = () => {
            isMountedRef.current = false;
            removeFromMatchingQueue({ participantId });
            clearInterval(intervalRefCurrentStatus.current);
            clearTimeout(timeoutRefLongWait.current);
            clearInterval(intervalRefMindsetQuote.current);
            window.removeEventListener("beforeunload", cleanup);
        };
        window.addEventListener("beforeunload", cleanup);
        return cleanup;
    }, [participantId]);

    // update group members once loaded if groupedOnLoad
    useEffect(() => {
        if (
            currentStatus.status === "groupedOnLoad" &&
            groupId &&
            group.data?.participants
        ) {
            setCurrentStatus({
                status: "groupedOnLoad",
                data: {
                    groupId,
                    participants:
                        group.data?.participants.map((p) => ({
                            id: p.id,
                            firstName: p.user?.firstName || "",
                            lastName: p.user?.lastName || "",
                            avatarUrl: p.user?.avatarUrl || "",
                        })) || [],
                },
            });
        }
    }, [group.data, groupId, currentStatus.status]);

    // Set various UI states when matching status changes
    useEffect(() => {
        if (currentStatus.status === "groupedOnLoad") {
            setProgressBarsComplete([true, true, true]);
            setStatusText(statusTextVariants.groupedExisting.complete);
            setTransitionIn(false);
            setShowGroupmates(true);
            setShowFooter(true);
            readySound.play().catch(() => {});
        }
        if (currentStatus.status === "grouped") {
            clearInterval(intervalRefCurrentStatus.current);
            clearTimeout(timeoutRefLongWait.current);
            setProgressBarsComplete([true, false, false]);
            setStatusText(statusTextVariants.groupedNew.start);
            setTimeout(() => {
                matchedSound.play().catch(() => {});
                setProgressBarsComplete([true, true, false]);
                setStatusText(statusTextVariants.groupedNew.matched);
                setShowGroupmates(true);
            }, 5000);
            setTimeout(() => {
                setStatusText(statusTextVariants.groupedNew.setup);
            }, 8000);
            setTimeout(() => {
                setProgressBarsComplete([true, true, true]);
                setStatusText(statusTextVariants.groupedNew.complete);
                readySound.play().catch(() => {});
            }, 11000);
            setTimeout(() => {
                clearInterval(intervalRefMindsetQuote.current);
                setTransitionIn(false);
                setShowFooter(true);
            }, 12000);
        }
        if (currentStatus.status === "rescheduled") {
            clearInterval(intervalRefCurrentStatus.current);
            clearTimeout(timeoutRefLongWait.current);
            clearInterval(intervalRefMindsetQuote.current);
            setProgressBarsComplete([true, true, true]);
            setStatusText(statusTextVariants.rescheduled.complete);
            setTimeout(() => {
                setShowFooter(true);
            }, 1000);
        }
    }, [currentStatus.status]);

    let statusTextColor = "gray.400";
    if (
        statusText === statusTextVariants.groupedNew.matched ||
        statusText === statusTextVariants.groupedExisting.matched
    ) {
        statusTextColor = "blue.500";
    }
    if (
        statusText === statusTextVariants.rescheduled.complete ||
        statusText === statusTextVariants.groupedNew.complete ||
        statusText === statusTextVariants.groupedExisting.complete
    ) {
        statusTextColor = "gray.800";
    }

    const progressLoadingColor: ProgressBarVariant = "stepInProgress";
    const progressDoneColor: ProgressBarVariant = progressDone
        ? currentStatus.status.includes("grouped")
            ? "groupingDone"
            : "rescheduled"
        : "stepDone";

    return (
        <Flex w="100vw" h="100vh" flexDirection="column">
            <Header />
            <Flex
                w="100%"
                h="100%"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                {/* container */}
                <Flex flexDirection="column" align="center" w="480px" h="537px">
                    {/* loader + broadcasting */}
                    <Flex
                        flexDirection="column"
                        align="center"
                        w="100%"
                        h="319px"
                    >
                        {/* system status */}
                        <Flex
                            mb="16px"
                            h="56px"
                            direction="column"
                            alignSelf="flex-start"
                            justifyContent="flex-end"
                        >
                            {statusText.split("\n").map((text, i) => (
                                <Text
                                    key={i}
                                    color={statusTextColor}
                                    fontWeight="semibold"
                                    fontFamily="Inter"
                                    fontSize="22px"
                                    lineHeight="28px"
                                >
                                    {text}
                                </Text>
                            ))}
                        </Flex>
                        {/* loader */}
                        <Flex w="100%" h="6px">
                            {!progressDone && (
                                <Flex
                                    position="absolute"
                                    w="480px"
                                    justifyContent="center"
                                    gap="147px"
                                    zIndex="1"
                                >
                                    <Flex h="6px" w="20px" bgColor="white" />
                                    <Flex h="6px" w="20px" bgColor="white" />
                                </Flex>
                            )}
                            {!progressDone &&
                                progressBarsComplete.map((bar, i) => {
                                    return bar ? (
                                        <Progress
                                            key={i}
                                            h="100%"
                                            w="33%"
                                            value={100}
                                            variant={progressDoneColor}
                                        />
                                    ) : null;
                                })}
                            <Progress
                                h="100%"
                                w={loadingBarWidth}
                                value={100}
                                variant={
                                    progressDone
                                        ? progressDoneColor
                                        : progressLoadingColor
                                }
                                isIndeterminate={!progressDone}
                                zIndex="0"
                            />
                        </Flex>
                        <Flex mt="8px" alignSelf="flex-start" h="21px">
                            <Text
                                color="gray.500"
                                fontFamily="Inter"
                                fontWeight="400"
                                fontSize="13px"
                                lineHeight="21px"
                            >
                                {subHeaderText}
                            </Text>
                        </Flex>

                        {/* avatar container */}
                        <Flex
                            flexDirection="column"
                            align="center"
                            justify="center"
                            mt="72px"
                        >
                            {/* ------------------ */}
                            {/* -- broadcasting -- */}
                            {/* ------------------ */}
                            <Box
                                display={
                                    showGroupmates || showFooter
                                        ? "none"
                                        : undefined
                                }
                                position="absolute"
                                h="200px"
                                w="200px"
                                borderRadius="100px"
                                bgColor="#F5F9FF"
                                transformOrigin="center"
                                animation={`${broadcasting} 3.5s linear infinite .1s`}
                            />
                            <Box
                                display={
                                    showGroupmates || showFooter
                                        ? "none"
                                        : undefined
                                }
                                position="absolute"
                                h="200px"
                                w="200px"
                                borderRadius="100px"
                                bgColor="#EDF5FF"
                                transformOrigin="center"
                                animation={`${broadcasting} 3.5s linear infinite .7s`}
                            />
                            <Box
                                display={showGroupmates ? "none" : undefined}
                                position="absolute"
                                h="88px"
                                w="88px"
                                borderRadius="44px"
                                bgColor="blue.100"
                            />
                            {/* ------------------ */}

                            <Flex>
                                <Avatar
                                    name={`${account?.data?.firstName} ${account?.data?.lastName}`}
                                    src={account?.data?.avatarUrl || undefined}
                                    size="lg"
                                    showBorder={true}
                                    borderColor="white"
                                    borderWidth="2px"
                                    boxShadow="0 0 12px 6px rgba(26, 108, 229, 0.1)"
                                />

                                {/* --------------------- */}
                                {/* -- matched sparkle -- */}
                                {/* --------------------- */}
                                <Box
                                    position="absolute"
                                    mt="22px"
                                    ml="22px"
                                    h="20px"
                                    w="20px"
                                    opacity="0"
                                    borderRadius="10px"
                                    bgColor="blue.500"
                                    transformOrigin="center"
                                    animation={
                                        showGroupmates
                                            ? `${scaleFadeCircle} .5s linear 1 0s`
                                            : undefined
                                    }
                                />
                                <Box
                                    position="absolute"
                                    mt="2px"
                                    ml="2px"
                                    h="60px"
                                    w="60px"
                                    opacity="0"
                                    borderWidth="1px"
                                    borderColor="blue.500"
                                    borderRadius="30px"
                                    transformOrigin="center"
                                    animation={
                                        showGroupmates
                                            ? `${scaleFadeOutline} .5s linear 1 .2s`
                                            : undefined
                                    }
                                />
                                <Image
                                    src={MatchedSparkleAvatar}
                                    position="absolute"
                                    mt="-36px"
                                    ml="-36px"
                                    opacity="0"
                                    transformOrigin="center"
                                    animation={
                                        showGroupmates
                                            ? `${scaleFadeSparkle} .5s linear 1 .5s}`
                                            : undefined
                                    }
                                />
                                {/* --------------------- */}
                            </Flex>

                            {showGroupmates && (
                                <Flex
                                    position="absolute"
                                    justify="center"
                                    align="center"
                                    gap="8px"
                                    mt="-64px"
                                    ml="-64px"
                                >
                                    {otherGroupParticipants.map((member, i) => {
                                        const top = [
                                            "55px",
                                            "-55px",
                                            "-60px",
                                            "60px",
                                        ];
                                        const left = [
                                            "60px",
                                            "-55px",
                                            "50px",
                                            "-50px",
                                        ];
                                        return (
                                            <Flex
                                                direction="column"
                                                position="absolute"
                                                key={member.id}
                                            >
                                                <Tooltip
                                                    placement="bottom"
                                                    label={member.firstName}
                                                    mt="-18px"
                                                    borderRadius="12px"
                                                    padding="2px 8px"
                                                    bgColor="gray.700"
                                                >
                                                    <Avatar
                                                        position="absolute"
                                                        mt={top[i]}
                                                        ml={left[i]}
                                                        name={`${member.firstName} ${member.lastName}`}
                                                        src={
                                                            member.avatarUrl ||
                                                            undefined
                                                        }
                                                        size="lg"
                                                        showBorder={true}
                                                        borderColor="white"
                                                        borderWidth="2px"
                                                        boxShadow="0 0 12px 6px rgba(26, 108, 229, 0.1)"
                                                        opacity="0"
                                                        transformOrigin="center"
                                                        animation={`${scaleFadeIn} .8s linear 1 .8s forwards`}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                    {/* mindset */}
                    {currentStatus.status !== "rescheduled" && (
                        <Flex
                            direction="column"
                            align="center"
                            w="364px"
                            mt="44px"
                        >
                            <Flex flex="1 1 30%" />
                            <Flex flex="1">
                                <SlideFade
                                    in={transitionIn}
                                    transition={{
                                        enter: { duration: 0.5, delay: 1 },
                                        exit: { duration: 0.3 },
                                    }}
                                    offsetY="40px"
                                >
                                    <Text
                                        color="gray.900"
                                        fontFamily="Proxima Nova"
                                        fontSize="16px"
                                        lineHeight="24px"
                                        textAlign="center"
                                    >
                                        {mindsetQuotes[selectedQuoteIndex]}
                                    </Text>
                                </SlideFade>
                            </Flex>
                            <Flex flex="1 1 70%" />
                        </Flex>
                    )}
                </Flex>

                {/* footer */}
                {showFooter && (
                    <MatchingFooter
                        matchingStatus={currentStatus.status}
                        restartMatching={restartMatching}
                    />
                )}
            </Flex>
        </Flex>
    );
};
