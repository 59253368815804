import { useOwnResources, useStepContent } from "../hooks/useSessionContent";
import { useSelfData } from "../hooks/useImmersion";
import { Flex } from "@chakra-ui/react";
import { IFrameResource } from "./resources/IFrameResource";
import { ImageResource } from "./resources/ImageResource";
import { getEtherpadUrl } from "../../utils";
interface Props {
    imagePosition: "left" | "right";
}

export const SplitImageEtherpad = (props: Props) => {
    const { imagePosition } = props;
    const { resources } = useStepContent();
    const ownResources = useOwnResources();
    const self = useSelfData();
    const resource1 = resources?.resource1?.[0];
    const resource2 = resources?.resource2?.[0];
    const image = resource1?.type === "Image file" ? resource1 : resource2;
    const etherpad =
        ownResources.resource1?.type === "Etherpad"
            ? ownResources?.resource1
            : ownResources?.resource2;
    const { url: imageUrl = "", name: imageName = "" } = image ?? {};
    const {
        url: etherpadBaseUrl = "",
        name: etherpadName = "",
        usageInSession: etherpadUsageInSession = "",
        systemId: etherpadSystemId = "",
    } = etherpad ?? {};
    const participantName = self?.firstName ?? "";

    const etherpadUrl = getEtherpadUrl(etherpadBaseUrl, etherpadSystemId, participantName);

    return (
        <Flex w="100%" gap="8px" justifyContent="center">
            {imagePosition === "left" && (
                <ImageResource url={imageUrl} altText={imageName} />
            )}
            <IFrameResource
                url={etherpadUrl}
                name={etherpadName}
                headerType={
                    etherpadUsageInSession === "individualized"
                        ? "solo"
                        : "group"
                }
                resourceType="etherpad"
            />
            {imagePosition === "right" && (
                <ImageResource url={imageUrl} altText={imageName} />
            )}
        </Flex>
    );
};
