import {
    Button,
    Divider,
    Flex,
    Image,
    Text,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import HelpIcon from "design/assets/HelpIcon.svg";
import { useImmersion, useIsChimeEnabled } from "./hooks/useImmersion";
import { Presence } from "./Presence";
import { useShouldJoinVideo } from "./hooks/useSessionContent";
import { JoinVideoModal } from "./video/JoinVideoModal";
import { JoinVideoModalChime } from "./video_chime/JoinVideoModal";
import { Logo } from "../Logo";
import { IMMERSION_SIZES } from "./Immersion";

export const Header = () => {
    const { HEADER_HEIGHT } = IMMERSION_SIZES;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { groupId } = useParams();
    const navigate = useNavigate();
    const immersion = useImmersion();
    const shouldJoinVideo = useShouldJoinVideo(true);
    const isChimeEnabled = useIsChimeEnabled();

    return (
        <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottomColor="gray.50"
            borderBottomWidth="1px"
            paddingX="20px"
            h={HEADER_HEIGHT}
            backgroundColor="white"
        >
            <Flex alignItems={"center"} gap="12px">
                <Logo />
                <Divider orientation="vertical" h="30px"></Divider>
                <Text fontWeight="semibold">
                    {immersion?.data?.immersion.tactic}
                </Text>
            </Flex>
            <Flex alignItems={"center"} gap="12px">
                {shouldJoinVideo ? (
                    <Button
                        onClick={onOpen}
                        marginRight="10px"
                        size="sm"
                        variant={"outline"}
                        alignSelf="center"
                        fontWeight="bold"
                    >
                        Join Video
                    </Button>
                ) : null}
                {isChimeEnabled ? (
                    <JoinVideoModalChime
                        isOpen={isOpen}
                        onClose={onClose}
                    ></JoinVideoModalChime>
                ) : (
                    <JoinVideoModal
                        isOpen={isOpen}
                        onClose={onClose}
                    ></JoinVideoModal>
                )}
                {groupId && <Presence></Presence>}
                <Divider
                    marginLeft="10px"
                    h="30px"
                    orientation="vertical"
                ></Divider>
                <Flex>
                    <Tooltip
                        label="Open live chat support"
                        placement="bottom-end"
                    >
                        <Button
                            bg="white"
                            px="0px"
                            // @ts-ignore
                            onClick={() => window.$chatwoot.toggle()}
                            _hover={{ bg: "gray.100" }}
                        >
                            <Flex
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                gap="2px"
                            >
                                <Image src={HelpIcon} alt="Help icon" w="20px" h="20px" />
                                <Text
                                    color="gray.700"
                                    fontFamily="Inter"
                                    fontWeight="400"
                                    fontSize="10px"
                                    lineHeight="10px"
                                    justifySelf="flex-end"
                                >
                                    Help
                                </Text>
                            </Flex>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        label="Exit the immersion and return to dashboard"
                        placement="bottom-end"
                    >
                        <Button
                            _hover={{
                                bg: "danger.-5",
                                color: "danger.0",
                            }}
                            color="gray.700"
                            bg="white"
                            px="0px"
                            onClick={() => navigate("/")}
                        >
                            <Flex
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                gap="2px"
                            >
                                <ArrowRightOnRectangleIcon
                                    width="20px"
                                    height="20px"
                                />
                                <Text
                                    fontFamily="Inter"
                                    fontWeight="400"
                                    fontSize="10px"
                                    lineHeight="10px"
                                    justifySelf="flex-end"
                                >
                                    Exit
                                </Text>
                            </Flex>
                        </Button>
                    </Tooltip>
                </Flex>
            </Flex>
        </Flex>
    );
};
