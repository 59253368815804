import { Button, Flex, Image, Link, Text, VStack } from "@chakra-ui/react";
import { useTRPC } from "../../hooks/useTRPC";
import { CalendarIcon } from "@heroicons/react/24/outline";

import { DateTime } from "luxon";
import {
    SESSION_DEFAULT_BACKGROUND_COLOR,
    extractImmersionTitle,
} from "../utils";
import FallbackSessionImage from "../../assets/default_immersion_square.png";
import { FaceFrownIcon } from "@heroicons/react/24/outline";

const AvailableSessions = () => {
    const trpc = useTRPC();
    const { data: availableSessions, isLoading } =
        trpc.sessions.availableSessions.useQuery();

    if (isLoading || !availableSessions) {
        return (
            <VStack width="full" alignItems="start">
                <Text fontSize="md" fontWeight="semibold">
                    Sessions Now Available
                </Text>
                <Flex
                    bg="gray.50"
                    width="full"
                    height="40"
                    borderRadius="12px"
                />
            </VStack>
        );
    }

    return (
        <Flex direction="column" width="full" gap="4" alignItems="start">
            <Text fontSize="md" fontWeight="semibold" mb="2">
                Sessions Now Available
            </Text>
            {(availableSessions?.length ?? 0) === 0 && (
                <Flex
                    bg="gray.50"
                    width="full"
                    height="32"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="12px"
                >
                    <Text color="gray.500" fontSize="lg" fontWeight="normal">
                        You don't have any sessions starting right now.
                    </Text>
                </Flex>
            )}

            {availableSessions?.map((session: any) =>
                !session.time ? null : (
                    <Flex
                        key={session.id}
                        gap="4"
                        w="full"
                        justifyContent="space-between"
                        direction={["column", null, "row"]}
                        alignItems={["center", null, "center"]}
                        maxHeight={[null, null, "200px"]}
                        p="2"
                        borderRadius="12px"
                        borderColor="gray.100"
                        borderWidth="thin"
                        shadow="sm"
                        _hover={{
                            shadow: "lg",
                        }}
                    >
                        <Flex direction={["column", null, "row"]}>
                            <Flex
                                bg={
                                    session.immersion
                                        .thumbnailBackgroundColor ||
                                    SESSION_DEFAULT_BACKGROUND_COLOR
                                }
                                minWidth="200px"
                                width="200px"
                                height="156px"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="8px"
                            >
                                <Image
                                    maxHeight="82px"
                                    src={
                                        session.immersion.templateUrl ||
                                        FallbackSessionImage
                                    }
                                    alt={""}
                                />
                            </Flex>
                            <VStack
                                ml="32px"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Flex alignItems="flex-start" gap="12px">
                                    <Flex mt="1px">
                                        <CalendarIcon height="24px" />
                                    </Flex>
                                    <Flex direction="column">
                                        <Text
                                            fontWeight="semibold"
                                            fontSize={["18px", null, "base"]}
                                        >
                                            {DateTime.fromISO(
                                                session.time,
                                            ).toLocaleString({
                                                weekday: "long",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "2-digit",
                                            })}
                                        </Text>
                                        <Text
                                            fontStyle="italic"
                                            fontSize="12px"
                                            color="dark.400"
                                        >
                                            {
                                                Intl.DateTimeFormat().resolvedOptions()
                                                    .timeZone
                                            }
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Text
                                    mt="16px"
                                    fontSize={["16px", null, "base"]}
                                >
                                    {extractImmersionTitle(session.immersion)}
                                </Text>
                            </VStack>
                        </Flex>
                        {session.events.some(
                            (e: any) =>
                                e.type === "CLONE_RESOURCES" &&
                                e.status === "COMPLETE",
                        ) ? (
                            <Button
                                mx="4"
                                bg="blue.500"
                                color="white"
                                fontSize="md"
                                as="a"
                                href={`/immersion/${session.id}/intro/0`}
                                minWidth={["140px", null, "172px"]}
                                maxWidth={["140px", null, "172px"]}
                                minHeight={["40px", null, "52px"]}
                                maxHeight={["40px", null, "52px"]}
                                gap="8px"
                                padding="15px 20px"
                                _hover={{ bg: "blue.600" }}
                            >
                                Join Session
                            </Button>
                        ) : (
                            <Flex
                                p="8px 16px 8px 12px"
                                bgColor="danger.-5"
                                alignSelf="flex-start"
                                alignItems="center"
                                borderRadius="8px"
                                borderColor="danger.-4"
                                borderWidth="1px"
                                gap="20px"
                            >
                                <Flex gap="8px" alignItems="center">
                                    <FaceFrownIcon
                                        color="#DA332B"
                                        height="20px"
                                        width="20px"
                                    />
                                    <Text
                                        color="danger.0"
                                        fontFamily="Inter"
                                        fontSize="10px"
                                        fontWeight="600"
                                        textTransform="uppercase"
                                        lineHeight="normal"
                                    >
                                        Failed to load
                                    </Text>
                                </Flex>

                                <Link
                                    color="gray.800"
                                    fontFamily="Inter"
                                    fontSize="10px"
                                    lineHeight="normal"
                                    textDecoration="underline"
                                    // @ts-ignore
                                    onClick={() => window.$chatwoot.toggle()}
                                >
                                    Contact Support
                                </Link>
                            </Flex>
                        )}
                    </Flex>
                ),
            )}
        </Flex>
    );
};

export default AvailableSessions;
