import { Flex } from "@chakra-ui/react";
import { useSelfData } from "../hooks/useImmersion";
import { useOwnResources } from "../hooks/useSessionContent";
import { IFrameResource } from "./resources/IFrameResource";
import { ImageResource } from "./resources/ImageResource";
import { ResourceType } from "./resources/ResourceHeader";
import { RichtextResource } from "./resources/RichtextResource";
import { ChatbotResource } from "./resources/ChatbotResource";
import { getEtherpadUrl } from "../../utils";

type Props = {
    resourceType: ResourceType | "richtext";
};

export const SplitResourceChatbot = (props: Props) => {
    const { resourceType } = props;
    const ownResources = useOwnResources();
    const self = useSelfData();

    const participantName = self?.firstName ?? "";

    const leftResource = ownResources?.resource1;
    const baseUrl = leftResource?.url ?? "";
    const name = leftResource?.name ?? "";
    const usageInSession = leftResource?.usageInSession ?? "";
    const systemId = leftResource?.systemId ?? "";
    const altText = leftResource?.altText ?? "";

    const chatbotResource = ownResources?.resource2;
    const chatbotSystemId = chatbotResource?.systemId ?? "";

    let leftResourceComponent = null;
    switch (resourceType) {
        case "etherpad":
            const etherpadUrl = getEtherpadUrl(baseUrl, systemId, participantName);
            leftResourceComponent = (
                <IFrameResource
                    url={etherpadUrl}
                    name={name}
                    headerType={
                        usageInSession === "individualized" ? "solo" : "group"
                    }
                    resourceType="etherpad"
                />
            );
            break;
        case "image":
            leftResourceComponent = (
                <ImageResource url={baseUrl} altText={name} />
            );
            break;
        case "richtext":
            leftResourceComponent = <RichtextResource altText={altText} />;
    }

    return (
        <Flex w="100%" gap="16px" justifyContent="center">
            {leftResourceComponent}
            <ChatbotResource systemId={chatbotSystemId} headerType="chatbot" />
        </Flex>
    );
};
