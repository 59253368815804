import {
    Button,
    Center,
    Divider,
    Flex,
    Icon,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import {
    InformationCircleIcon,
    PhoneArrowUpRightIcon,
    UserGroupIcon,
} from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";

import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAccount, useAreVideosRecorded } from "../../hooks/useAccount";
import { trpc } from "../../hooks/useTRPC";
import { useSelfData } from "../hooks/useImmersion";
import {
    currentAudioInputDeviceId,
    currentVideoInputDeviceId,
    isAudioEnabledState,
    isBackgroundBlurRequestedState,
    isVideoEnabledState,
    localDevicesState,
    meetingEventState,
    meetingSession,
} from "../immersionState";
import { ConnectByPhoneModal } from "../video_common/ConnectByPhoneModal";
import {
    ConnectionErrorModal,
    ErrorModalData,
} from "../video_common/ConnectionErrorModal";
import { handleConnectionError, meetingErrorHelpMessage } from "./ErrorLib";

import { InPersonConfirmationModal } from "./InPersonConfirmationModal";
import { useAudioLevel } from "./hooks/useAudioLevel";
import { useChime } from "./hooks/useChime";
import { useLocalVideoBlur } from "./hooks/useLocalVideoBlur";
import { useTimedValue } from "./hooks/useTimedValue";
import { AudioMenu } from "./joinVideoModal/AudioMenu";
import { JoinVideoModalVideoPreview } from "./joinVideoModal/JoinVideoModalVideoPreview";
import { VideoMenu } from "./joinVideoModal/VideoMenu";

const TOOLTIP_TEXT =
    "Your company has authorized Sparkwise to record this session. This helps us continuously improve our content and platform. Rest assured, the recording is for internal use only.";

const CHIME_MEETING_START_TIMEOUT = 30000; // 30 seconds

export const menuStyles = {
    menuOptionGroup: {
        color: "dark.400",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "15px",
    },
    menuItem: {
        color: "gray.700",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "17px",
        padding: "8px 14px",
        _checked: {
            color: "blue.500",
        },
        sx: {
            "&[aria-checked=true] svg": {
                color: "blue.500",
            },
        },
    },
};

export const JoinVideoModalChime = memo(
    ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
        const { requestDevicePermissions } = useChime();

        const {
            isOpen: errorOpen,
            onOpen: onErrorOpen,
            onClose: onErrorClose,
        } = useDisclosure();
        const {
            isOpen: isPhoneModalOpen,
            onOpen: onPhoneModalOpen,
            onClose: onPhoneModalClose,
        } = useDisclosure();
        const {
            isOpen: inPersonModalOpen,
            onOpen: onInPersonModalOpen,
            onClose: onInPersonModalClose,
        } = useDisclosure();

        const setupRoom = trpc.immersion.setupRoom.useMutation();
        const isRecorded = useAreVideosRecorded();
        const { id, groupId } = useParams();
        const account = useAccount();

        const audioInputDevice = useRecoilValue(currentAudioInputDeviceId);
        const [videoDevice, setVideoDevice] = useRecoilState(
            currentVideoInputDeviceId,
        );

        const isAudioEnabled = useRecoilValue(isAudioEnabledState);
        const [isVideoEnabled, setIsVideoEnabled] =
            useRecoilState(isVideoEnabledState);
        const setRequestBackgroundBlur = useSetRecoilState(
            isBackgroundBlurRequestedState,
        );

        const createChimeAttendee =
            trpc.immersion.createChimeAttendee.useMutation();
        const participantId = useSelfData().sessionParticipantUuid;

        const setMeetingSession = useSetRecoilState(meetingSession);
        const meetingEvent = useRecoilValue(meetingEventState);
        const [joinVideoRoomLoading, setJoinVideoRoomLoading] = useState(false);

        const buttonRef = React.createRef<HTMLButtonElement>();
        const devices = useRecoilValue(localDevicesState);

        const [error, setError] = useState<ErrorModalData | null>(null);
        const [videoLoading, setVideoLoading] = useState<boolean>(false);
        const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | null>();
        const [audioTrack, setAudioTrack] = useState<MediaStreamTrack | null>();
        const audioLevel = useAudioLevel(audioTrack);
        const [deviceController, setDeviceController] =
            useState<DefaultDeviceController | null>(null);
        const [firstVisit, setFirstVisit] = useState(true);

        const { getBackgroundBlurDevice } = useLocalVideoBlur();

        /**
         * This flag is used to signal a Chime Session is being attempted
         */
        const [isAwaitingSession, setIsAwaitingSession] =
            useState<boolean>(false);
        /**
         * This timer is used to time out a Chime Session attempt
         */
        const {
            start,
            cancel,
            value: timedOut,
        } = useTimedValue(false, CHIME_MEETING_START_TIMEOUT);

        // Start the Chime Session timeout
        useEffect(() => {
            if (isAwaitingSession) {
                start(true);
            }
        }, [isAwaitingSession]);

        const closeAudioVideo = useCallback(
            async (deviceController: DefaultDeviceController) => {
                console.log(`[AV Modal]: Stopping media streams`);
                await deviceController.stopAudioInput();
                console.log(`[AV Modal]: Stopped audio stream`);
                await deviceController.stopVideoInput();
                console.log(`[AV Modal]: Stopped video stream`);
                setAudioTrack(null);
                setVideoTrack(null);
                await deviceController.destroy();
                console.log(`[AV Modal]: Destroyed device controller`);
                setDeviceController(null);
            },
            [setAudioTrack, setVideoTrack, setDeviceController],
        );

        /**
         * Wait for a session to fully start before closing the modal.
         */
        useEffect(() => {
            if (!isAwaitingSession) {
                return;
            }
            if (timedOut) {
                console.log(`[Chime]: Timed out waiting for meeting session`);
                Sentry.captureException(`Chime meeting timed out`);
                setError({
                    title: "Error connecting to the video chat",
                    message: [
                        `Could not join the meeting. Please verify your network connection and try again.`,
                    ],
                    permissionsError: false,
                });
                onClose();
                onErrorOpen();
                return;
            }
            if (meetingEvent?.name === "meetingStartSucceeded") {
                cancel();
                setIsAwaitingSession(false);
                onClose();
                setJoinVideoRoomLoading(false);
            }
        }, [isAwaitingSession, meetingEvent, timedOut]);

        /**
         * Video device change handler
         */
        useEffect(() => {
            (async () => {
                setVideoLoading(true);
                if (!deviceController || !isOpen) {
                    setVideoLoading(false);
                    return;
                }
                await deviceController.stopVideoInput();
                setVideoTrack(null);
                if (!isVideoEnabled) {
                    setVideoLoading(false);
                    return;
                }
                const device = await getBackgroundBlurDevice();
                if (!device) {
                    setVideoLoading(false);
                    return;
                }
                const mediaStream =
                    await deviceController.startVideoInput(device);
                if (mediaStream) {
                    const track = mediaStream.getVideoTracks()[0];
                    setVideoTrack(track);
                }
                setVideoLoading(false);
            })();
        }, [isOpen, deviceController, getBackgroundBlurDevice, isVideoEnabled]);

        /**
         * Audio device change handler
         */
        useEffect(() => {
            (async () => {
                if (!deviceController || !isOpen) {
                    return;
                }
                await deviceController.stopAudioInput();
                setAudioTrack(null);
                if (!isAudioEnabled || !audioInputDevice) {
                    return;
                }
                Sentry.captureMessage(
                    "Audio Device Requested: " + audioInputDevice,
                );
                const mediaStream =
                    await deviceController.startAudioInput(audioInputDevice);
                if (mediaStream) {
                    const track = mediaStream.getAudioTracks()[0];
                    setAudioTrack(track);
                }
            })();
        }, [isOpen, audioInputDevice, deviceController, isAudioEnabled]);

        /**
         * Device Controller initialization
         */
        useEffect(() => {
            if (isOpen && !deviceController && !isAwaitingSession) {
                let logger = new ConsoleLogger("JoinVideo", LogLevel.INFO);
                const avDeviceController = new DefaultDeviceController(logger);
                avDeviceController.chooseVideoInputQuality(640, 480, 30);
                setDeviceController(avDeviceController);
                console.log(`[AV Modal]: Created Video Device Controller`);
                setError(null);
                // Track if it's the user's first ever visit to the Join Video Modal.
                setFirstVisit(!localStorage.getItem("HasVisited"));
                localStorage.setItem("HasVisited", "true");
            }
        }, [isOpen, videoDevice, audioInputDevice, deviceController]);

        /**
         * Background Blur Processor initialization
         */
        useEffect(() => {
            if (isOpen) {
                setRequestBackgroundBlur(true);
            } else {
                setRequestBackgroundBlur(false);
            }
        }, [isOpen]);

        /**
         * Device permissions handler
         */
        useEffect(() => {
            (async () => {
                if (isOpen && !audioInputDevice) {
                    try {
                        await requestDevicePermissions();
                    } catch (e) {
                        console.log(e);
                        return;
                    }
                }
            })();
        }, [isOpen, videoDevice, audioInputDevice]);

        useEffect(() => {
            (async () => {
                if (!isOpen && !isAwaitingSession && deviceController) {
                    await closeAudioVideo(deviceController);
                }
            })();
            return () => {
                if (deviceController) {
                    closeAudioVideo(deviceController);
                }
            };
        }, [isOpen, deviceController, isAwaitingSession]);

        useEffect(() => {
            if (meetingEvent) {
                // Handle specific error events and show the modal
                const errorMessage = meetingErrorHelpMessage(
                    meetingEvent.name,
                    meetingEvent.attributes,
                );
                if (errorMessage && errorMessage.isUserFacing) {
                    setError({
                        title: "Meeting Error",
                        message: errorMessage.messages,
                        permissionsError: false,
                    });
                    onErrorOpen();
                }
            }
        }, [meetingEvent, onErrorOpen]);

        const joinVideoRoom = useCallback(async (): Promise<void> => {
            localStorage.removeItem(`NoVideo-${id}`);
            setJoinVideoRoomLoading(true);
            setIsAwaitingSession(true);
            Sentry.captureMessage(
                `Connecting via ${videoDevice} & ${audioInputDevice}`,
            );
            try {
                if (deviceController) {
                    await closeAudioVideo(deviceController);
                }
                if (!videoDevice) {
                    setIsVideoEnabled(false);
                }

                const roomId = `${process.env.NODE_ENV}-${groupId}`;

                // Setup room
                const meeting = await setupRoom.mutateAsync({
                    sessionId: id || "",
                    roomId,
                    recordingEnabled: isRecorded || false,
                });

                // Create Chime attendee
                const attendee = await createChimeAttendee.mutateAsync({
                    roomId,
                    participantId,
                });

                const logger = new ConsoleLogger("Meeting", LogLevel.ERROR);

                const meetingDeviceController = new DefaultDeviceController(
                    logger,
                    {
                        enableWebAudio: true,
                    },
                );
                const configuration = new MeetingSessionConfiguration(
                    meeting,
                    attendee,
                );
                const meetingSession = new DefaultMeetingSession(
                    configuration,
                    logger,
                    meetingDeviceController,
                );

                meetingSession.audioVideo.chooseVideoInputQuality(640, 480, 30);

                meetingSession.audioVideo.start();
                if (!isAudioEnabled) {
                    meetingSession.audioVideo.realtimeMuteLocalAudio();
                }
                setMeetingSession(meetingSession);
            } catch (e: any) {
                Sentry.captureException(e);
                setError({
                    title: "Error connecting to the video chat",
                    message: handleConnectionError(e),
                    permissionsError: false,
                });
                onClose();
                onErrorOpen();
            }
        }, [deviceController, videoDevice, isAudioEnabled]);
        return (
            <>
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay></ModalOverlay>
                    <ModalContent maxWidth="600px" padding="0px">
                        <ModalBody padding="0px">
                            <Flex
                                flexDirection="column"
                                alignItems="center"
                                padding="32px 40px 24px 40px"
                            >
                                <Text
                                    fontFamily="Inter"
                                    fontSize="20px"
                                    fontWeight="600"
                                    lineHeight="26px"
                                    marginBottom="8px"
                                >
                                    Set up your camera and microphone
                                </Text>
                                <Text
                                    fontFamily="Inter"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    marginBottom="10px"
                                >
                                    Connect to video to meet your group
                                </Text>
                                <JoinVideoModalVideoPreview
                                    videoTrack={videoTrack}
                                    audioTrack={audioTrack}
                                    firstVisit={firstVisit}
                                    loading={videoLoading}
                                />

                                {!!devices?.length && (
                                    <Flex
                                        flexDirection="row"
                                        width="100%"
                                        gap="8px"
                                        justifyContent="space-between"
                                    >
                                        <AudioMenu audioLevel={audioLevel} />

                                        <VideoMenu />
                                    </Flex>
                                )}
                                <Flex
                                    flexDirection="column"
                                    marginTop="40px"
                                    gap="12px"
                                    width="300px"
                                >
                                    <Button
                                        ref={buttonRef}
                                        colorScheme="dark"
                                        background="gray.900"
                                        borderRadius="8px"
                                        isDisabled={
                                            !audioInputDevice || !account.data
                                        }
                                        isLoading={joinVideoRoomLoading}
                                        fontSize="16px"
                                        fontWeight="600"
                                        onClick={async () => joinVideoRoom()}
                                    >
                                        Join video
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            onPhoneModalOpen();
                                        }}
                                        variant="outline"
                                        borderRadius="8px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        colorScheme="gray"
                                        background="white"
                                        isDisabled={isAwaitingSession}
                                        mb={"20px"}
                                    >
                                        <Icon
                                            as={PhoneArrowUpRightIcon}
                                            mr={2}
                                            fontSize={"16px"}
                                        />
                                        Dial-in from a phone
                                    </Button>
                                    <Center>
                                        <Text
                                            fontSize={"14px"}
                                            fontWeight={500}
                                            display={"inline-flex"}
                                            alignItems={"center"}
                                        >
                                            <Icon
                                                as={UserGroupIcon}
                                                mr={1}
                                                fontSize={"16px"}
                                            />{" "}
                                            Is your group in person?
                                            <Link
                                                color="gray.700"
                                                fontWeight="400"
                                                onClick={() => {
                                                    return onInPersonModalOpen();
                                                }}
                                                ml={1}
                                            >
                                                Click here
                                            </Link>
                                            .
                                        </Text>
                                    </Center>
                                </Flex>
                            </Flex>
                            <Divider />
                            <Flex
                                direction="column"
                                alignItems="center"
                                padding={`20px 40px ${isRecorded ? "12px" : "20px"} 40px`}
                                gap="12px"
                            >
                                <Flex direction="column" alignItems="center">
                                    <Text
                                        fontFamily="Inter"
                                        fontSize="16px"
                                        fontWeight="400"
                                        lineHeight="22px"
                                        color="dark.600"
                                    >
                                        Having trouble connecting?
                                        <Link
                                            ml={1}
                                            color="blue.500"
                                            textDecoration="underline"
                                            fontWeight="600"
                                            onClick={() =>
                                                // @ts-ignore
                                                window.$chatwoot.toggle()
                                            }
                                        >
                                            request live support.
                                        </Link>
                                    </Text>
                                </Flex>
                                {isRecorded && (
                                    <Flex alignItems="flex-start">
                                        <Text
                                            fontFamily="Inter"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            color="dark.400"
                                        >
                                            This session will be recorded.
                                        </Text>
                                        <Tooltip label={TOOLTIP_TEXT}>
                                            <InformationCircleIcon
                                                stroke="#C2C5CC"
                                                height="16px"
                                            />
                                        </Tooltip>
                                    </Flex>
                                )}
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <ConnectByPhoneModal
                    isOpen={isPhoneModalOpen}
                    onClose={onPhoneModalClose}
                    isChime={true}
                    onSuccess={() => onClose()}
                />
                <ConnectionErrorModal
                    isOpen={errorOpen}
                    onClose={onErrorClose}
                    error={error}
                    isChime
                />
                <InPersonConfirmationModal
                    open={inPersonModalOpen}
                    onCancel={onInPersonModalClose}
                    onContinue={() => {
                        Sentry.captureMessage("in-person-1");
                        localStorage.setItem(`NoVideo-${id}`, "true");
                        onInPersonModalClose();
                        onClose();
                    }}
                />
            </>
        );
    },
);
