import {
    AspectRatio,
    Box,
    Center,
    Image,
    Spinner,
    Text,
} from "@chakra-ui/react";
import NoVideo from "design/assets/NoVideo.svg";
import React from "react";
import { useRecoilValue } from "recoil";
import { videoPermissionState } from "../../immersionState";
import { useVideoDevices } from "../hooks/useVideoDevices";
import { ParticipantBox } from "../ParticipantBox";
import { AudioVideoControls } from "./AudioVideoControls";

export const JoinVideoModalVideoPreview: React.FC<{
    videoTrack?: MediaStreamTrack | null;
    audioTrack?: MediaStreamTrack | null;
    firstVisit: boolean;
    loading: boolean;
}> = ({ videoTrack, audioTrack, firstVisit, loading }) => {
    const ref = React.createRef<HTMLDivElement>();
    const videoPermission = useRecoilValue(videoPermissionState);
    const devices = useVideoDevices();
    const hasVideoDevice = devices.length !== 0;

    const showPermissions = videoPermission === "prompt";

    const comp = React.useMemo(() => {
        let Component = <ParticipantBox transform={"scaleX(-1)"} />;

        if (loading) {
            Component = (
                <Center height="100%">
                    <Spinner size="lg" speed=".65s" color="gray.700" />
                </Center>
            );
        } else if (showPermissions) {
            Component = (
                <Center height="100%">
                    <Text color="black" transform={"scaleX(-1)"}>
                        Click "<b>Allow</b>" when prompted
                    </Text>
                </Center>
            );
        } else if (!hasVideoDevice) {
            Component = (
                <Center height="100%" transform={"scaleX(-1)"}>
                    <Image
                        src={NoVideo}
                        alt={"No video input devices detected"}
                    />
                </Center>
            );
        } else if (videoTrack) {
            Component = (
                <video
                    ref={(video) => {
                        if (video) {
                            video.srcObject = new MediaStream([videoTrack]);
                        }
                    }}
                    autoPlay={true}
                    playsInline={true}
                    style={{
                        maxWidth: "none",
                        maxHeight: "100%",
                    }}
                />
            );
        }
        return Component;
    }, [videoTrack, showPermissions, loading]);

    return (
        <Box position="relative" marginTop="15px">
            <AspectRatio ratio={3.5 / 3} w={279}>
                <Box
                    transform={"scaleX(-1)"}
                    borderRadius="12px"
                    overflow="hidden"
                    width="279px"
                    height="210px"
                    ref={ref}
                    background="gray.100"
                    marginBottom="20px"
                >
                    {comp}
                </Box>
            </AspectRatio>
            {!showPermissions ? (
                <AudioVideoControls audioTrack={audioTrack} />
            ) : null}
        </Box>
    );
};
