import { Box, Flex, Image, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import AudioErrorIcon from "../../../../assets/audio_error.svg";
import {
    audioPermissionState,
    isAudioEnabledState,
} from "../../../immersionState";
import MicLevelButton from "../../components/MicLevelButton";
import { useChimeModals } from "../../hooks/useChimeModals";

export const AudioControlButton: React.FC<{
    audioTrack?: MediaStreamTrack | null;
}> = ({ audioTrack }) => {
    const [isAudioEnabled, setIsAudioEnabled] =
        useRecoilState(isAudioEnabledState);
    const audioPermission = useRecoilValue(audioPermissionState);
    const { microphonePermissionsModal } = useChimeModals();

    const showWarning = audioPermission === "denied" || !isAudioEnabled;

    return (
        <Flex
            w={'36px'}
            h={'36px'}
            borderRadius={8}
            bg={"white"}
            _hover={{
                cursor: "pointer",
                backgroundColor: "gray.50",
                color: "gray.800",
            }}
            position={"relative"}
            onClick={() => {
                if (audioPermission === "granted") {
                    setIsAudioEnabled(!isAudioEnabled);
                } else {
                    microphonePermissionsModal.onOpen();
                }
            }}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Tooltip
                hasArrow
                placement="top"
                label={
                    audioPermission === "denied"
                        ? "Allow access to microphone"
                        : null
                }
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                bg="gray.800"
                color="gray.100"
                padding="8px"
                textAlign={"center"}
                borderRadius={"4px"}
                p={"8px"}
            >
                <Image
                    src={AudioErrorIcon}
                    display={showWarning ? "block" : "none"}
                    w="18px"
                    h="18px"
                    alt={"Video device alert"}
                    position={"absolute"}
                    top={"-8px"}
                    right={"-8px"}
                />
            </Tooltip>
            <MicLevelButton
                localAudioTrack={audioTrack}
                micEnabled={isAudioEnabled && audioPermission === "granted"}
            />
        </Flex>
    );
};
