import { Box, Center, Text } from "@chakra-ui/react";
import {
    useParticipants as useSessionParticipants,
    useSelfData, useSessionParticipant,
} from "../../hooks/useImmersion";
import { meetingSession } from "../../immersionState";
import { useAccount } from "../../../hooks/useAccount";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { TranscriptEvent } from "amazon-chime-sdk-js";
import useRoster from "../hooks/useRoster";

export const ChimeCaptions: React.FC = () => {
    const thisParticipantChimeAttendeeId = useSessionParticipant()?.chimeAttendeeId;
    const account = useAccount();
    const isSuperAdmin = account.data?.isSparkwiseAdmin;

    const [caption, setCaption] = useState<{
        caption: string;
        attendeeId: string;
    } | null>(null);

    const meeting = useRecoilValue(meetingSession);
    const { roster } = useRoster();
    const participantId =
        (caption ? roster[caption.attendeeId] : null)?.externalUserId;
    const sessionParticipants = useSessionParticipants();
    const thisParticipant = sessionParticipants.find(
        (_) => {
            if (participantId) {
                return _.id === participantId
            } else {
                return _.chimeAttendeeId === thisParticipantChimeAttendeeId
            }
        },
    );

    useEffect(() => {
        if (!isSuperAdmin) {
            return;
        }
        const transcriptionHandler = (event: TranscriptEvent) => {
            if ("type" in event) {
                return;
            } else {
                event.results.map((result) => {
                    const alternative = result.alternatives[0];
                    const item = alternative.items[0];
                    if (alternative && item && item.attendee.attendeeId) {
                        setCaption({
                            caption: alternative.transcript,
                            attendeeId: item.attendee.attendeeId,
                        });
                    }
                });
            }
        };
        if (meeting?.audioVideo) {
            meeting.audioVideo.transcriptionController?.subscribeToTranscriptEvent(
                transcriptionHandler,
            );
        }
        return () => {
            if (meeting) {
                meeting.audioVideo.transcriptionController?.unsubscribeFromTranscriptEvent(
                    transcriptionHandler,
                );
            }
        };
    }, [meeting?.audioVideo, isSuperAdmin]);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (caption) {
            timer = setTimeout(() => {
                setCaption(null);
            }, 3000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [caption]);

    const participantName = thisParticipant
        ? `${thisParticipant.user.firstName}: `
        : null;

    return (
        <Box position={"absolute"} bottom={24} w={"100%"} h={10} zIndex={999} display={'none'} id={'captions'}>
            <Center>
                {caption ? (
                    <Text
                        color={"white"}
                        textShadow={"2px 2px 10px black"}
                        fontSize={34}
                    >
                        {participantName}
                        {caption?.caption}
                    </Text>
                ) : null}
            </Center>
        </Box>
    );
};
